import logo from "../logo.svg";
import "../App.css";
import moment from "moment";
import { useState, useEffect } from "react";
import myImage from "../images/update.gif";
import App from "../App";
import FooterDrop from "./footerDrop";
import AdvertisementComponent from "../utilities/advertismentComponent";
const momenttz = require("moment-timezone");
function DaySattaResult({ dayGameData }) {
  // var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  const [data, setGameData] = useState([]);

  const currentTime = moment().format("HH:mm");
  const currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    setGameData(dayGameData);
  }, [dayGameData]);

  // based on current date and time get data
  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };
  return (
    <div>
      {/* <App sectiondata={gameResult} /> */}

      <div className="col-12 pt-5 daywisereport">
        <h3 className="text-white text-center">Matka Result</h3>
        <div className="row">
          {data && data.length > 0 ? (
            data.map((gameData, index) => (
              <div key={index} className="col-md-6 col-sm-12 game_column">
                <div className="d-flex align-items-center flex-column col-lg-12">
                  <h6 className="mb-0 pt-2 fw-bold fs-6">
                    {gameData?.game_name}
                  </h6>
                  <p className="mb-0 fs-6 textColor">
                    ( Time {gameData?.open_time} )
                  </p>
                  <div className="d-flex align-items-end text-center">
                    <div>
                      <p className="mb-0 fs-6">OLD</p>
                      <span className="btn">
                        {gameData?.prev_date?.result || "{ }"}
                      </span>
                    </div>
                    <div>
                      <img src={myImage} alt="updateimg"></img>
                    </div>
                    <div>
                      <p className="mb-0 fs-6">NEW</p>
                      <span className="btn">{getTodayResult(gameData)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p></p>
          )}
        </div>
        <AdvertisementComponent type="random" />
        <FooterDrop />
      </div>
    </div>
  );
}

export default DaySattaResult;
