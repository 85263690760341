import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import App from "./App";
import FooterDrop from "./js/footerDrop";
import DaySattaResult from "./js/daySattaResult";
import DetailAcord from "./js/detailAccord";
import Disclaimer from "./js/disclaimer";
import Advertisement from "./js/advertisement";
import Fqa from "./js/fqa";
import FooterSocial from "./js/footerSocial";

const RootComponent = () => {
  return (
    <div className="main-div">
      <div className="container-fluid">
        <App />
        <DetailAcord />
        <Fqa />
        <FooterSocial />
      </div>
    </div>
  );
};

ReactDOM.render(
  <Router>
    <RootComponent />
  </Router>,
  document.getElementById("root")
);
