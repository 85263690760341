function Fqa() {
    return (
        <div className="fqa">
            <div className="col-12 cardDetails text-center">
                <h1>SATTA KING FQA</h1>
            </div>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <p>Q1. What is the Satta king game?</p>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne">
                        <div className="accordion-body">
                            <p>Ans. Satta King is a sort of lottery game used in 'Games of chance' based on numbers from 01 to 00. People bet money in the Satta Matka game on the numbers picked between 01 and 00. After that, a number is drawn from the pot. Whatever the number of people will entice, the award will be won, and the people said to the king. Winner of satta Matka is called the Satta king.</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Q2. What are the types of satta games?
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo">
                        <div className="accordion-body text-center">
                            <p>Ans. There's no sort of Satta king game itself. When people in Satta king start a new game, people call the game with various towns, states or locations. E.g., Gali, Peshawar, Ghaziabad, Faridabad, morning Mumbai, king Delhi, king Dubai, etc. Though there are many types of Satta king games played in India, four games (Gali, Disawar, Ghaziabad, Faridabad) are the most played in India. The most famous match in Peshawar. This game is from Dubai.</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Q3. How did the numbers work in satta?
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree">
                        <div className="accordion-body text-center">
                            <p>Ans. Suppose if someone has the best 10 rupees on a number, the user gets 10 x 90 = 900 rupees when that number is opened. Similarly, for rs 50, rs 9,000 for rs 100, rs 13,500 for rs 150, rs 18,000 for rs 200, the player will get rs 4,500. The user can spend as much money on a number as he likes and play as many numbers as he likes.</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Q4. How could we win the satta game?
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour">
                        <div className="accordion-body">
                            <p>Ans. Through being a perfect estimate of speculative professors and satta King Black, the speculative tribe could be won. By playing with 4 or 3 Jodi daily, you can perform forged and get all these Jodi out of your website that we publish on our no cost game website</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            Q5. How can I know more about satta king?
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive">
                        <div className="accordion-body">
                            <p>Ans. The https://satta-king-black.com is quick to play, adding essentially two thoughts to the 1st huraf and the 2nd Jodi. The side points of haraf 0,1,2,3,4,5,6,7,8,9 ander or bhar one are called haraf. Jodi, which is open at 01,02 .... 00, is available, and two sides of bhar are called Jodi.</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            Q6. How was satta started?
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix">
                        <div className="accordion-body">
                            <p>Ans. Ratan khatri launched ago matka in 1961-59 years ago. And Worli Matka was the very first name of Matka. Plus, it reached its height in the 80s and 90s. Matka has continued to expand quite rapidly since then. And it's own a very loving and exciting game from players wherever you can win money while playing with.</p>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            Q7. What is the win rate?
                        </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven">
                        <div className="accordion-body">
                            <p>Ans. Huraf - 100-900₹
                                Jodi - 100-9000₹
                                10% Dealer commission</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Fqa;
